import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Surface, Button, Spinner } from '@vst/beam';

import {
  AEMContentProvider,
  AemFeature,
  HeaderWithBackNavigation,
  createAemUrl,
} from '@mfe/shared/components';
import { ADDON_PRODUCT_KIND, useScreenResolution } from '@mfe/shared/util';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import {
  getAvailableNumbers,
  selectCurrentShopAddon,
} from '@mfe/to-be-migrated/redux/addOns';
import useNavigate from '@mfe/services/navigation';
import { ShopAddonsLink } from '@mfe/legacy/mv/utils/Navigation';
import { Confirm } from '@mfe/shared/components';

import { AddonCart } from '../cart';
import Steps from '../steps';
import styles from './styles.module.scss';

const OneStepCheckout: React.FC<{
  goToConfigure: () => void;
  handleSubmit: () => void;
}> = ({ goToConfigure, handleSubmit }) => {
  const { t } = useTranslation('ShopAddons');
  const dispatch = useDispatch();
  const { goBack, replace } = useNavigate();

  const { aemBaseUrl } = useSelector(selectConfig);
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const {
    userInfo: {
      accountType,

      address: { service },
    },
  } = useSelector(selectUserInfo);
  const currentShopAddon = useSelector(selectCurrentShopAddon);
  const isVoice = currentShopAddon?.kind === ADDON_PRODUCT_KIND.voice;

  const { isMedium, isLarge, isExtraLarge } = useScreenResolution();
  const isDesktop = isLarge || isExtraLarge;

  const [aemFragmentLoading, setAEMFragmentLoading] = useState(true);
  const [aemFragmentError, setAEMFragmentError] = useState(false);

  useEffect(() => {
    if (!currentShopAddon) {
      replace(`/${ShopAddonsLink.ShopAddons}`);
    }
  }, [replace, currentShopAddon]);

  if (!currentShopAddon) {
    return null;
  }

  const aemURL = createAemUrl({
    baseUrl: aemBaseUrl,
    locale: userLocale,
    feature: mapAddonKindToAemUrlFragment(currentShopAddon.kind),
    accountType,
  });

  const primaryActionLabel = isVoice
    ? t('voice.addAndConfigure')
    : t('subscribeNow');

  const handlePrimaryAction = () => {
    if (isVoice) {
      dispatch(getAvailableNumbers(service?.postalCode));
      goToConfigure();
    } else {
      handleSubmit();
    }
  };

  const headerClassName = isVoice ? styles['voiceHeader'] : styles['header'];

  return (
    <div className={styles['wrapper']}>
      {aemFragmentError ? (
        <Error goBack={() => replace(`/${ShopAddonsLink.ShopAddons}`)} />
      ) : (
        <>
          <HeaderWithBackNavigation
            handleBackNavigation={goBack}
            title={currentShopAddon.name}
            goBackLabel={t('allAddOns')}
            additionalClassName={headerClassName}
          />
          {isVoice && (
            <Surface
              variant={isDesktop || isMedium ? 'secondary' : 'primary'}
              mb={isDesktop || isMedium ? '64px' : '8px'}
            >
              <Steps step={1} maxSteps={2} />
            </Surface>
          )}
          <div className={styles['container']}>
            <Surface className={styles['aem-fragment-surface']}>
              {aemFragmentLoading && (
                <div style={{ padding: '32px' }}>
                  <Spinner label={t('cart.aemFragment.loading')} />
                </div>
              )}
              <AEMContentProvider
                id={styles['aem-fragment-container']}
                style={{ display: aemFragmentLoading ? 'none' : 'block' }}
                src={aemURL}
                onLoad={() => setAEMFragmentLoading(false)}
                onError={() => setAEMFragmentError(true)}
              />

              <div className={styles['submit-container']}>
                <Button onClick={handlePrimaryAction}>
                  {primaryActionLabel}
                </Button>
              </div>
            </Surface>
            <AddonCart
              handleClick={handlePrimaryAction}
              addon={currentShopAddon}
              submitButtonLabel={primaryActionLabel}
            />
          </div>
        </>
      )}
    </div>
  );
};

const Error = ({ goBack }: { goBack: () => void }) => {
  const { t } = useTranslation('ShopAddons');

  return (
    <Confirm
      title={t('cart.aemFragment.error.title')}
      description={t('cart.aemFragment.error.description')}
      buttons={[
        {
          children: t('cart.aemFragment.error.goBackLabel'),
          onClick: () => {
            goBack();
          },
        },
      ]}
    />
  );
};

function mapAddonKindToAemUrlFragment(kind: string) {
  if (kind === ADDON_PRODUCT_KIND.voice) {
    return AemFeature.ADD_ON_VOICE;
  }

  if (kind === ADDON_PRODUCT_KIND.shield) {
    return AemFeature.ADD_ON_SHIELD;
  }

  if (kind === ADDON_PRODUCT_KIND.easyCare) {
    return AemFeature.ADD_ON_EASYCARE;
  }

  return AemFeature.ADD_ON_STATIC_IP;
}

export default OneStepCheckout;
