import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Surface,
  Button,
  Txt,
  InlineLink,
  Icon,
  StandardModal,
} from '@vst/beam';
import { Offer } from '@vst/beam-icons/icons';

import { ProductType } from '@mfe/shared/schema-types';
import { AddonIcon } from '@mfe/shared/components';

import { getPriceAndDiscount } from '../addons-grid/utils';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';

type AddonCartProps = {
  addon: ProductType;
  submitButtonLabel: string;
  handleClick: () => void;
};

export const AddonCart: React.FC<AddonCartProps> = ({
  addon,
  submitButtonLabel,
  handleClick,
}) => {
  const { t } = useTranslation('ShopAddons');
  const { name, kind } = addon;
  const {
    price = undefined,
    oldPrice = undefined,
    totalPromotionDuration = undefined,
    promotions = [],
  } = getPriceAndDiscount(addon);
  const { availableNumbersLoading } = useSelector(selectAddOns);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const hasPromotions = promotions.length > 0;

  function handleTermsModalClose() {
    setIsTermsModalOpen(false);
  }

  return (
    <div className={styles['cart']}>
      <Surface radius="16px" p="24px">
        <div className={styles['vertical-spacing']}>
          <Txt variant="bodyLargeBold" color="subtle">
            {t('cart.title')}
          </Txt>
          <div className={styles['content']}>
            <Addon name={name} kind={kind} />
            <div className={styles['subscription-price']}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Txt variant="bodySmallRegular" color="regular">
                  {t('cart.subscription')}
                </Txt>
                <Txt variant="bodySmallRegular" color="regular">
                  {t('cart.pricePerMonth', { price: oldPrice ?? price })}
                </Txt>
              </div>
              {hasPromotions && (
                <div className={styles['promotions']}>
                  {promotions.map((promotion, i) => (
                    <div key={i} className={styles['promotion']}>
                      <Txt
                        component="p"
                        variant="bodySmallBold"
                        color="success"
                        className={styles['promotion-name']}
                      >
                        <span>{promotion.name}</span>
                        <Icon icon={Offer} size={16} />
                      </Txt>
                      <Txt variant="bodySmallBold" color="success">
                        {t('cart.pricePerMonth', {
                          price: Math.abs(promotion.amount),
                          context: 'discount',
                        })}
                      </Txt>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={styles['total']}>
              <Txt variant="heading6" color="regular">
                {t('cart.total')}
              </Txt>
              <Txt
                variant="heading6"
                color="regular"
                style={{ textAlign: 'right' }}
              >
                {price === 0
                  ? t('cart.free')
                  : t('cart.pricePerMonth', {
                      price,
                    })}
                <div>
                  {hasPromotions &&
                    oldPrice !== undefined &&
                    totalPromotionDuration !== undefined && (
                      <Txt component="p" variant="smallItalic" color="subtle">
                        {t('cart.priceAfterDiscount', {
                          price: oldPrice,
                          duration: totalPromotionDuration,
                        })}
                      </Txt>
                    )}
                  <Txt component="p" variant="smallRegular" color="regular">
                    {t('cart.taxes')}
                  </Txt>
                </div>
              </Txt>
            </div>
          </div>
          <Txt component="p" variant="smallRegular" color="subtle">
            {t('cart.termsAndConditions.beforeLink')}{' '}
            <InlineLink
              variant="primary"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsTermsModalOpen(true);
              }}
            >
              {t('cart.termsAndConditions.link')}
            </InlineLink>
            {t('cart.termsAndConditions.afterLink')}
          </Txt>
          <Button
            width="100%"
            onClick={handleClick}
            disabled={availableNumbersLoading}
          >
            {submitButtonLabel}
          </Button>
          <Txt component="p" variant="tinyRegular" color="subtle">
            {t('cart.taxesDisclaimer')}
          </Txt>
        </div>
      </Surface>

      <StandardModal
        isOpen={isTermsModalOpen}
        header="Terms and Conditions"
        actions={{
          primary: { label: 'Close', onClick: handleTermsModalClose },
        }}
        onClose={handleTermsModalClose}
        size="medium"
      >
        <div>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis
          deleniti laboriosam ipsum culpa nihil quibusdam temporibus quis hic,
          distinctio, similique blanditiis vero atque possimus veniam neque
          tempora animi et molestias. Sit sequi ipsam suscipit deserunt
          dignissimos exercitationem consequuntur doloribus mollitia. Nostrum
          cumque laboriosam totam alias ex ut, officia deleniti quibusdam, quos
          rerum minus! Voluptate reprehenderit asperiores, consequuntur tenetur
          vero facere! Dolorum itaque minima a dignissimos, provident fuga
          tempore molestiae corrupti impedit earum explicabo neque unde nam illo
          dolor maxime possimus? Odio nemo deserunt dolorum ipsum dicta,
          perferendis fuga sunt facilis! Enim odit nulla in laboriosam
          recusandae dignissimos sint numquam quasi reprehenderit quos,
          voluptatibus ex non accusamus aspernatur asperiores!
        </div>
      </StandardModal>
    </div>
  );
};

type AddonProps = {
  name: string;
  kind: string;
};

const Addon: React.FC<AddonProps> = ({ name, kind }) => {
  return (
    <div className={styles['addon']}>
      <AddonIcon kind={kind} className={styles['icon']} />
      <Txt variant="paragraphBold" color="regular">
        {name}
      </Txt>
    </div>
  );
};
