import {
  Surface,
  Txt,
  Spacer,
  Checkbox,
  TextField,
  validators,
  ConventionalAutocomplete,
  Button,
  Form,
} from '@vst/beam';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FC, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDownward } from '@vst/beam-icons/icons';

import {
  selectAddOns,
  setVoiceFormValues,
} from '@mfe/to-be-migrated/redux/addOns';
import { Address, Locale } from '@mfe/shared/schema-types';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

import { Step } from './step';
import { STATES } from './constants';
import { StepInstanceProps } from './types';
import { AddressInput } from './address-input';
import { TransitionWrapper } from './transition-wrapper';
import styles from './styles.module.scss';

export const Step2: FC<StepInstanceProps> = ({
  isOpen,
  openStep,
  setOpenStep,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ShopAddons');
  const [isValid, setIsValid] = useState(false);

  const {
    voiceConfig: {
      emergencyAddress: {
        streetAddress,
        cityAddress,
        stateProvince,
        zipCode,
        aptUnit,
      },
      useServiceAddressForE911,
    },
  } = useSelector(selectAddOns);

  const {
    userInfo: {
      address: { service },
    },
  } = useSelector(selectUserInfo);

  const serviceAddress = createAddress(service, t);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData: any = new FormData(e.target as HTMLFormElement);
    const formValues = Object.fromEntries(formData.entries());

    setIsValid(true);
    setOpenStep(openStep + 1);
    dispatch(setVoiceFormValues({ emergencyAddress: { ...formValues } }));
  };

  const handleCheckboxChange = () => {
    dispatch(
      setVoiceFormValues({
        useServiceAddressForE911: !useServiceAddressForE911,
      })
    );
  };

  return (
    <Step
      step={2}
      title={t('voice.e911.title')}
      isValid={isValid}
      handleEdit={() => setOpenStep(2)}
    >
      <TransitionWrapper isOpen={isOpen}>
        {isOpen && (
          <Form
            initialValues={{
              streetAddress,
              cityAddress,
              stateProvince,
              zipCode,
              aptUnit: aptUnit ?? '',
              useServiceAddressForE911,
            }}
            onSubmit={handleSubmit}
            validationMode="onSubmit"
            className={styles.formContent}
          >
            <Txt variant={'bodySmallRegular'}>
              {t('voice.e911.description')}
            </Txt>
            <Spacer y={'24px'} />
            <Checkbox
              id="terms"
              checked={useServiceAddressForE911}
              onChange={handleCheckboxChange}
              label={t('voice.e911.sameAddress')}
            />
            {useServiceAddressForE911 ? (
              <Txt
                variant="bodySmallRegular"
                pt={'24px'}
                style={{ maxWidth: '156px' }}
              >
                {serviceAddress}
              </Txt>
            ) : (
              <Surface
                variant={'primary'}
                pt={'24px'}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Surface className={styles.formGroup}>
                  <AddressInput
                    fluid
                    required
                    id="streetAddress"
                    name="streetAddress"
                    title={t('voice.e911.streetAddress')}
                    placeholder={t('voice.e911.streetAddress')}
                    labelProps={{
                      labelText: t('voice.e911.streetAddress'),
                    }}
                    value={streetAddress}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      const text = event.currentTarget.value;
                      dispatch(
                        setVoiceFormValues({
                          emergencyAddress: { streetAddress: text },
                        })
                      );
                    }}
                    validation={{
                      required: t('voice.validation.required'),
                    }}
                  />
                  <TextField
                    fluid
                    id={'aptAddress'}
                    name={'aptAddress'}
                    title={'Apt/Suite'}
                    placeholder={t('voice.e911.aptUnit')}
                    labelProps={{
                      labelText: `${t('voice.e911.aptUnit')} ${t(
                        'voice.e911.optional'
                      )}`,
                    }}
                    value={aptUnit}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      const text = event.currentTarget.value;
                      dispatch(
                        setVoiceFormValues({
                          emergencyAddress: { aptUnit: text },
                        })
                      );
                    }}
                  />
                </Surface>
                <Spacer y={'24px'} />
                <Surface className={styles.formGroup}>
                  <TextField
                    fluid
                    required
                    id="cityAddress"
                    name="cityAddress"
                    title={t('voice.e911.city')}
                    placeholder={t('voice.e911.city')}
                    labelProps={{ labelText: t('voice.e911.city') }}
                    value={cityAddress}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      const text = event.currentTarget.value;
                      dispatch(
                        setVoiceFormValues({
                          emergencyAddress: { cityAddress: text },
                        })
                      );
                    }}
                    validationRules={[
                      validators.required({
                        message: t('voice.validation.required'),
                      }),
                    ]}
                  />
                  <ConventionalAutocomplete
                    fluid
                    required
                    id={'stateProvince'}
                    name={'stateProvince'}
                    title={t('voice.e911.state')}
                    placeholder={stateProvince}
                    labelProps={{
                      labelText: t('voice.e911.stateProvince'),
                    }}
                    value={stateProvince}
                    options={STATES}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      const text = event.target as HTMLInputElement;
                      dispatch(
                        setVoiceFormValues({
                          emergencyAddress: { stateProvince: text.value },
                        })
                      );
                    }}
                    validationRules={[
                      validators.required({
                        message: t('voice.validation.required'),
                      }),
                    ]}
                  />
                  <TextField
                    fluid
                    required
                    id="zipCode"
                    name="zipCode"
                    title={t('voice.e911.zip')}
                    placeholder={t('voice.e911.zip')}
                    labelProps={{ labelText: t('voice.e911.zip') }}
                    value={zipCode}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      const number = event.currentTarget.value;
                      dispatch(
                        setVoiceFormValues({
                          emergencyAddress: { zipCode: number },
                        })
                      );
                    }}
                    validationRules={[
                      validators.define((value) =>
                        validatePostalCode(Number(value), t)
                      ),
                    ]}
                  />
                </Surface>
              </Surface>
            )}
            <Button
              type="submit"
              variant={'secondary'}
              icon={ArrowDownward}
              iconPos={'right'}
              className={styles.nextButton}
            >
              {t('voice.nextStep')}
            </Button>
          </Form>
        )}
      </TransitionWrapper>
    </Step>
  );
};

function validatePostalCode(value: number, t: TFunction) {
  const userLocale = Locale.EnUs;
  const postalCode = String(value || '').trim();

  if (postalCode.length === 0) {
    return { error: true, message: t('voice.validation.postalCode') };
  }

  if (userLocale === Locale.EnUs && !/^\d{5}([ -]\d{4})?$/.test(postalCode)) {
    return { error: true, message: t('voice.validation.postalCode') };
  }

  return { error: false, message: '' };
}

function createAddress(
  addressInput: Address | undefined | null,
  t: TFunction
): string {
  if (!addressInput || addressInput.addressLines.length === 0) {
    return t('voice.noAddress');
  }
  const line1 = addressInput.addressLines[0] ?? '';
  const line2 = addressInput.addressLines[1] ?? '';
  const line3 = `${addressInput.municipality}, ${addressInput.region} ${addressInput.postalCode}`;

  let parsedString = line1;

  if (line2) {
    parsedString += `\n${line2}`;
  }
  parsedString += `\n${line3}`;

  return parsedString;
}
