import {
  Button,
  Form,
  InlineLink,
  SectionAlert,
  Txt,
  ConventionalSelect,
  Spacer,
  TextField,
  validators,
  SpinnerInline,
} from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { FC, FormEvent, useState } from 'react';
import { ArrowDownward } from '@vst/beam-icons/icons';
import { useSelector, useDispatch } from 'react-redux';

import {
  getAvailableNumbers,
  reserveSelectedNumber,
  selectAddOns,
  setVoiceFormValues,
} from '@mfe/to-be-migrated/redux/addOns';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

import { Step } from './step';
import { StepInstanceProps } from './types';
import { TransitionWrapper } from './transition-wrapper';
import styles from './styles.module.scss';

export const Step1: FC<StepInstanceProps> = ({
  isOpen,
  openStep,
  setOpenStep,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ShopAddons');
  const {
    userInfo: {
      address: { service },
    },
  } = useSelector(selectUserInfo);
  const {
    voiceConfig: { postalCode },
    availableNumbers,
  } = useSelector(selectAddOns);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [isZipCodeOpen, setIsZipCodeOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handlePhoneNumberSubmit = () => {
    if (!availableNumbers || availableNumbers.length === 0) {
      return;
    }

    setIsValid(true);
    dispatch(reserveSelectedNumber(phoneNumber));
    setOpenStep(openStep + 1);
  };

  return (
    <Step
      step={1}
      title={t('voice.phone.title')}
      isValid={isValid}
      handleEdit={() => setOpenStep(1)}
    >
      {!isOpen && (
        <Txt variant="bodyLargeRegular" color="subtle">
          {phoneNumber}
        </Txt>
      )}
      <TransitionWrapper isOpen={isOpen}>
        {isOpen && (
          <>
            <SectionAlert variant="infoSecondary" showIcon={false}>
              <div className={styles.stepOneSectionAlert}>
                <Txt variant="smallBold" component="span">
                  {t('voice.hasExisting')}
                </Txt>
                <Txt variant="smallRegular">{t('voice.phone.transfer')}</Txt>
                <InlineLink href="#" onClick={(e) => e.preventDefault()}>
                  {t('voice.phone.carePhoneNumber')}
                </InlineLink>
                <Txt variant="smallRegular">{t('voice.phone.getStarted')}</Txt>
              </div>
            </SectionAlert>
            {isZipCodeOpen ? (
              <ZipCodeForm setIsZipCodeOpen={setIsZipCodeOpen} />
            ) : (
              <div>
                <Spacer y="24px" />
                <Txt variant="linkBodySmall">
                  {t('voice.phone.numbersAvailable')}
                </Txt>
                <Txt variant="bodySmallBold" component="span">
                  {postalCode || service?.postalCode}{' '}
                </Txt>
                <InlineLink
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsZipCodeOpen(true);
                  }}
                >
                  <Txt variant="smallRegular" component="span">
                    {t('voice.change')}
                  </Txt>
                </InlineLink>
              </div>
            )}
            <Spacer y="24px" />
            <PhoneNumberForm
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              isZipCodeOpen={isZipCodeOpen}
              handleSubmit={handlePhoneNumberSubmit}
            />
          </>
        )}
      </TransitionWrapper>
    </Step>
  );
};

const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

const ZipCodeForm: FC<{
  setIsZipCodeOpen: (isOpen: boolean) => void;
}> = ({ setIsZipCodeOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ShopAddons');
  const {
    voiceConfig: { postalCode },
  } = useSelector(selectAddOns);

  const handleCancel = () => {
    setIsZipCodeOpen(false);
  };

  const handleZipCodeSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData: any = new FormData(e.target as HTMLFormElement);
    const formValues = Object.fromEntries(formData.entries());

    dispatch(setVoiceFormValues({ postalCode: formValues.zip }));
    dispatch(getAvailableNumbers(formValues.zip));
    setIsZipCodeOpen(false);
  };

  return (
    <Form
      initialValues={{ zip: postalCode }}
      validationMode="onSubmit"
      onSubmit={handleZipCodeSubmit}
      className={styles.formContent}
    >
      <div style={{ display: 'flex', flexFlow: 'column' }}>
        <Spacer y="24px" />
        <TextField
          fluid
          required
          name="zip"
          defaultValue={postalCode}
          labelProps={{ labelText: t('voice.phone.zipLabel') }}
          validationRules={[
            validators.required({ message: t('voice.validation.required') }),
            validators.pattern({
              pattern: zipCodeRegex,
              message: t('voice.validation.zipCode'),
            }),
          ]}
        />
        <div className={styles['zipCodeButtons']}>
          <Button type="button" variant="tertiary" onClick={handleCancel}>
            {t('voice.cancel')}
          </Button>
          <Button type="submit" variant="secondary">
            {t('voice.save')}
          </Button>
        </div>
      </div>
    </Form>
  );
};

const PhoneNumberForm: FC<{
  phoneNumber: string;
  setPhoneNumber: (phone: string) => void;
  isZipCodeOpen: boolean;
  handleSubmit: () => void;
}> = ({ phoneNumber, setPhoneNumber, isZipCodeOpen, handleSubmit }) => {
  const { availableNumbers, availableNumbersLoading, availableNumbersError } =
    useSelector(selectAddOns);
  const { t } = useTranslation('ShopAddons');

  const noNumbersFound =
    !availableNumbersLoading &&
    (availableNumbers?.length === 0 || availableNumbersError);

  return (
    <Form
      initialValues={{ phone: phoneNumber }}
      validationMode="onSubmit"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      className={styles.formContent}
    >
      <div className={styles['phoneNumberSelect']}>
        {availableNumbersLoading ? (
          <SpinnerInline label={t('voice.phone.loading')} />
        ) : noNumbersFound ? (
          <SectionAlert label={t('voice.phone.error')} variant={'error'} />
        ) : (
          <ConventionalSelect
            name="phone"
            fluid
            required
            labelProps={{ labelText: t('voice.phone.phoneLabel') }}
            placeholder={
              availableNumbersLoading
                ? t('voice.phone.loading')
                : t('voice.phone.select')
            }
            options={availableNumbers?.map((entry) => ({
              label: entry.phoneNumber,
              value: entry.phoneNumber,
            }))}
            validationRules={[
              validators.required({
                message: t('voice.validation.required'),
              }),
            ]}
            value={phoneNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPhoneNumber(e.target.value)
            }
            disabled={isZipCodeOpen}
          />
        )}
      </div>
      <Button
        type="submit"
        variant="secondary"
        icon={ArrowDownward}
        iconPos="right"
        className={styles.nextButton}
      >
        {t('voice.nextStep')}
      </Button>
    </Form>
  );
};
