import { createSlice } from '@reduxjs/toolkit';

import {
  FetchWithErrorsMutation,
  FetchWithErrorsQuery,
} from '@mfe/shared/redux/graphql';
import {
  Addon,
  AvailablePhoneNumber,
  DirectoryListType,
  ProductType,
} from '@mfe/shared/schema-types';

export enum AddOnType {
  Voice = 'Voice',
  EasyCare = 'EasyCare',
  Shield = 'Shield',
  StaticIP = 'StaticIP',
}

export interface AddOnsState {
  loading: boolean;
  error: FetchWithErrorsQuery['errors'] | null;
  addOns: {
    hasEasyCare?: boolean;
    hasVoice?: boolean;
    hasShield?: boolean;
    hasStaticIP?: boolean;
    hasOfficeHours?: boolean;
  };
  addOnsProductInstanceIds: string[];
  addOnsPricesAndDiscounts: [];
  availableAddons: {
    loading: boolean;
    error: FetchWithErrorsQuery['errors'] | null;
    productTypes: Array<ProductType> | null;
  };
  currentShopAddonId: string | null;
  currentAddons: Array<Addon> | null;
  availableNumbersLoading: boolean;
  availableNumbers: AvailablePhoneNumber[] | null;
  availableNumbersError: FetchWithErrorsQuery['errors'] | null;
  reserveNumberLoading: boolean;
  reserveNumberSuccess: boolean | null;
  reserveNumberError: FetchWithErrorsMutation['errors'] | null;
  configureVoiceLoading: boolean;
  configureVoiceSuccess: boolean | null;
  configureVoiceError: FetchWithErrorsMutation['errors'] | null;
  voiceConfig: {
    postalCode: string;
    selectedPhoneNumber: string;
    emergencyAddress: {
      streetAddress: string; // should be line 1 of addressLines
      aptUnit?: string; // should be line 2 of addressLines
      cityAddress: string; // municipality
      stateProvince: string; // region
      zipCode: string; // postalCode
    };
    useServiceAddressForE911: boolean;
    callerId: string;
    blockCallerId: boolean;
    directoryListingType: DirectoryListType | null;
    publishDirectoryListAddress: boolean;
    isStepValid?: boolean | null;
  };
  submitOrder: {
    loading: boolean;
    error: FetchWithErrorsQuery['errors'] | null;
    success: boolean;
  };
}

export const initialAddOnsState: AddOnsState = {
  loading: true,
  error: null,
  addOns: {},
  addOnsProductInstanceIds: [],
  addOnsPricesAndDiscounts: [],
  availableAddons: {
    loading: false,
    error: null,
    productTypes: null,
  },
  currentShopAddonId: null,
  currentAddons: null,
  availableNumbersLoading: false,
  availableNumbers: null,
  availableNumbersError: null,
  reserveNumberLoading: false,
  reserveNumberSuccess: null,
  reserveNumberError: null,
  configureVoiceLoading: false,
  configureVoiceSuccess: null,
  configureVoiceError: null,
  voiceConfig: {
    postalCode: '',
    selectedPhoneNumber: '',
    emergencyAddress: {
      streetAddress: '',
      aptUnit: '',
      cityAddress: '',
      stateProvince: '',
      zipCode: '',
    },
    useServiceAddressForE911: true,
    callerId: '',
    blockCallerId: false,
    directoryListingType: null,
    publishDirectoryListAddress: false,
    isStepValid: null,
  },
  submitOrder: {
    loading: false,
    error: null,
    success: false,
  },
};

export const addOnsSlice = createSlice({
  name: 'addOns',
  initialState: initialAddOnsState,
  reducers: {
    fetchAddOns: (state) => {
      state.loading = true;
    },
    setAddOns: (
      state,
      { payload }: { payload: Partial<AddOnsState['addOns']> }
    ) => {
      state.loading = false;
      state.addOns = { ...state.addOns, ...payload };
    },

    clearAddOnsProductInstanceIds: (state) => {
      state.addOnsProductInstanceIds = [];
    },

    setAddOnsProductInstanceIds: (
      state,
      { payload }: { payload: string[] | [] }
    ) => {
      state.addOnsProductInstanceIds.push(...payload);
    },

    getAddOnsPricesAndDiscounts: (state) => {
      state.loading = true;
    },
    setAddOnsPricesAndDiscounts: (state, { payload }) => {
      state.loading = false;
      state.addOnsPricesAndDiscounts = payload;
    },

    setCurrentShopAddonId: (state, { payload }: { payload: string | null }) => {
      state.currentShopAddonId = payload;
    },
    setVoiceFormValues: (state, { payload }) => {
      state.voiceConfig = { ...state.voiceConfig, ...payload };
    },
    setStepValid: (state, { payload }: { payload: boolean }) => {
      state.voiceConfig.isStepValid = payload;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    getPurchasableAddOns: (state) => {
      state.availableAddons.loading = true;
    },
    setPurchasableAddOns: (
      state,
      { payload }: { payload: Array<ProductType> }
    ) => {
      state.availableAddons.loading = false;
      state.availableAddons.productTypes = payload;
    },
    purchasableAddOnsError: (state, { payload }) => {
      state.availableAddons.loading = false;
      state.availableAddons.error = payload;
    },
    purchaseAddOn: (
      state,
      {
        payload,
      }: {
        payload: { productTypeId: string; shoppingCartId: string };
      }
    ) => {
      state.submitOrder.loading = true;
    },

    purchaseAddOnSuccess: (state, { payload }) => {
      state.submitOrder.loading = false;
      state.submitOrder.error = null;
      state.submitOrder.success = payload;
    },

    purchaseAddOnError: (state, { payload }) => {
      state.submitOrder.loading = false;
      state.submitOrder.error = payload;
    },
    resetAddOns: () => initialAddOnsState,
    resetSubmit: (state) => {
      state.submitOrder.loading = false;
      state.submitOrder.error = null;
      state.submitOrder.success = false;
    },
    setCurrentAddons: (
      state,
      { payload }: { payload: Array<Addon> | null }
    ) => {
      state.currentAddons = payload;
    },
    getAvailableNumbers: (state, action) => {
      state.availableNumbersLoading = true;
    },
    setAvailableNumbers: (state, action) => {
      state.availableNumbers = action.payload;
      state.availableNumbersLoading = false;
    },
    setAvailableNumbersError: (state, action) => {
      state.availableNumbersLoading = false;
      state.availableNumbersError = action.payload;
    },
    reserveSelectedNumber: (state, action) => {
      state.voiceConfig.selectedPhoneNumber = action.payload;
      state.reserveNumberLoading = true;
    },
    setNumberReservationSuccess: (state) => {
      state.reserveNumberLoading = false;
      state.reserveNumberSuccess = true;
    },
    setNumberReservationError: (state, action) => {
      state.reserveNumberSuccess = false;
      state.reserveNumberLoading = false;
      state.reserveNumberError = action.payload;
    },
    configureVoiceAddon: (state, action) => {
      state.configureVoiceLoading = true;
    },
    setConfigureVoiceSuccess: (state) => {
      state.configureVoiceLoading = false;
      state.configureVoiceSuccess = true;
    },
    setConfigureVoiceError: (state, action) => {
      state.configureVoiceLoading = false;
      state.configureVoiceError = action.payload;
      state.configureVoiceSuccess = false;
    },
  },
});

export const {
  fetchAddOns,
  setAddOns,
  resetAddOns,
  setAddOnsProductInstanceIds,
  getAddOnsPricesAndDiscounts,
  setError,
  setAddOnsPricesAndDiscounts,
  setCurrentShopAddonId,
  setCurrentAddons,
  setVoiceFormValues,
  setStepValid,
  getAvailableNumbers,
  setAvailableNumbers,
  setAvailableNumbersError,
  reserveSelectedNumber,
  setNumberReservationSuccess,
  setNumberReservationError,
  configureVoiceAddon,
  setConfigureVoiceSuccess,
  setConfigureVoiceError,
  purchaseAddOn,
  purchaseAddOnSuccess,
  purchaseAddOnError,
  getPurchasableAddOns,
  setPurchasableAddOns,
  purchasableAddOnsError,
  resetSubmit,
  clearAddOnsProductInstanceIds,
} = addOnsSlice.actions;

export const selectAddOns = (state: { addOns: AddOnsState }) => state.addOns;
export const selectCurrentShopAddon = (state: { addOns: AddOnsState }) => {
  const { currentShopAddonId } = state.addOns;

  const currentShopAddon = state.addOns.availableAddons.productTypes?.find(
    (addon) => addon.id === currentShopAddonId
  );

  if (!currentShopAddon) {
    return null;
  }

  return currentShopAddon;
};
