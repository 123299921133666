import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, StandAloneLink, Surface, Txt } from '@vst/beam';

import { selectAddOns, setStepValid } from '@mfe/to-be-migrated/redux/addOns';

import { Step1 } from './step-1';
import { Step2 } from './step-2';
import { Step3 } from './step-3';
import { Step4 } from './step-4';
import { Disclosures } from './disclosures';
import styles from './styles.module.scss';

export const ConfigureVoiceCard = ({
  handleBack,
  handleSubmit,
}: {
  handleBack: () => void;
  handleSubmit: () => void;
}) => {
  const { t } = useTranslation('ShopAddons');

  const [openStep, setOpenStep] = useState(1);
  const stepProps = { openStep, setOpenStep };
  return (
    <Surface responsive className={styles.content}>
      <Txt variant={'bodyLargeRegular'}>{t('voice.cardHeader')}</Txt>
      <>
        <Step1 isOpen={openStep === 1} {...stepProps} />
        <Step2 isOpen={openStep === 2} {...stepProps} />
        <Step3 isOpen={openStep === 3} {...stepProps} />
        <Step4 isOpen={openStep === 4} {...stepProps} />
      </>
      <Disclosures />
      <Surface>
        <hr className={styles.divider} />
        <Actions submit={handleSubmit} back={handleBack} />
      </Surface>
    </Surface>
  );
};

const Actions: FC<{ submit: () => void; back: () => void }> = ({
  submit,
  back,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ShopAddons');

  const {
    voiceConfig: { directoryListingType },
  } = useSelector(selectAddOns);

  const handleBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    back();
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (directoryListingType) {
      dispatch(setStepValid(true));
      submit();
    } else {
      dispatch(setStepValid(false));
    }
  };

  return (
    <div className={styles.buttons}>
      <StandAloneLink
        variant="primary"
        linkSize="medium"
        children={t('voice.backToVoiceFeatures')}
        href={'#'}
        data-cy="navigatorBackLink"
        onClick={handleBack}
      />
      <Button
        variant="primary"
        fluid
        type="submit"
        data-cy="navigatorContinueButton"
        onClick={handleSubmit}
      >
        {t('subscribeNow')}
      </Button>
    </div>
  );
};
