import { all, call, select, takeLatest, put, take } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  FetchWithErrorsMutation,
  FetchWithErrorsQuery,
  graphqlMutationWithErrors,
  graphqlQueryWithErrors,
} from '@mfe/shared/redux/graphql';
import { ConfigureVoiceInput } from '@mfe/shared/schema-types';

import {
  AddOnsState,
  selectAddOns,
  setAddOnsPricesAndDiscounts,
  setAddOnsProductInstanceIds,
  setError,
  fetchAddOns,
  getAvailableNumbers,
  reserveSelectedNumber,
  setAvailableNumbers,
  setAvailableNumbersError,
  setNumberReservationSuccess,
  setNumberReservationError,
  configureVoiceAddon,
  setConfigureVoiceError,
  setConfigureVoiceSuccess,
  purchaseAddOn,
  getPurchasableAddOns as getPurchasableAddOnsAction,
  setPurchasableAddOns,
  purchasableAddOnsError,
  purchaseAddOnError,
  purchaseAddOnSuccess,
} from './addOnsSlice';
import { selectUserInfo } from '../userInfo';
import { waitForUserInfo } from '../utils/utilsSagas';
import {
  CONFIGURE_VOICE,
  GET_ADD_ONS_PRICES_AND_DISCOUNTS,
  GET_AVAILABLE_PHONE_NUMBERS,
  GET_PURCHASABLE_ADDONS,
  PURCHASE_ADD_ON,
  RESERVE_PHONE_NUMBER,
} from './queriesAndMutations';

export function* waitForAddOnsProductInstanceIds() {
  const addons: AddOnsState = yield select(selectAddOns);

  if (addons.loading) {
    yield take(setAddOnsProductInstanceIds.type);
  }
}

export function* fetchPurchasableAddOns() {
  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_PURCHASABLE_ADDONS,
    variables: {},
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(purchasableAddOnsError(runtimeError ?? errors));
    return;
  }

  const addons = data?.getPurchasableAddOns;

  if (addons) {
    yield put(setPurchasableAddOns(addons));
  }
}

export function* fetchAddOnsPricesAndDiscounts() {
  yield put(fetchAddOns);

  const { addOnsProductInstanceIds } = yield select(selectAddOns);

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_ADD_ONS_PRICES_AND_DISCOUNTS,
    variables: { addOnsProductInstanceIds },
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setError(runtimeError ?? errors));
    return;
  }

  if (data?.getAddOnsPricesAndDiscounts) {
    yield put(
      setAddOnsPricesAndDiscounts(data.getAddOnsPricesAndDiscounts ?? [])
    );
  }
}

export function* fetchAvailablePhoneNumbers(action: PayloadAction<string>) {
  yield call(waitForUserInfo);
  const { userInfo } = yield select(selectUserInfo);

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_AVAILABLE_PHONE_NUMBERS,
    variables: {
      input: {
        postal_code: action.payload || userInfo.address.service?.postalCode,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setAvailableNumbersError(runtimeError ?? errors));
    return;
  }

  if (data?.getAvailableTelephoneNumbers) {
    yield put(setAvailableNumbers(data.getAvailableTelephoneNumbers));
  }
}

export function* reserveNumber(action: PayloadAction<string>) {
  const apiResponse: FetchWithErrorsMutation = yield call(
    graphqlMutationWithErrors,
    {
      mutation: RESERVE_PHONE_NUMBER,
      variables: { input: { telephoneNumber: action.payload } },
      fetchPolicy: 'no-cache',
    }
  );

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setNumberReservationError(runtimeError ?? errors));
    return;
  }

  if (data?.reserveTelephoneNumber?.success) {
    yield put(setNumberReservationSuccess());
  }
}

export function* configureVoice(action: PayloadAction<ConfigureVoiceInput>) {
  const apiResponse: FetchWithErrorsMutation = yield call(
    graphqlMutationWithErrors,
    {
      mutation: CONFIGURE_VOICE,
      variables: { input: action.payload },
      fetchPolicy: 'no-cache',
    }
  );

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setConfigureVoiceError(runtimeError ?? errors));
    return;
  }

  if (data?.configureVoice?.success) {
    yield put(setConfigureVoiceSuccess());
  }
}

export function* purchaseAddOnSaga(action: any) {
  const input = action.payload;

  const apiResponse: FetchWithErrorsMutation = yield call(
    graphqlMutationWithErrors,
    { mutation: PURCHASE_ADD_ON, variables: { input } }
  );

  const { data, errors, runtimeError } = apiResponse;

  if (data?.configureVoice?.success) {
    yield put(setConfigureVoiceSuccess());
    yield put(purchaseAddOnError(runtimeError ?? errors));
    return;
  }

  if (data?.purchaseAddOn?.success) {
    yield put(purchaseAddOnSuccess(data.purchaseAddOn.success));
  }

  if (!data?.purchaseAddOn?.success) {
    yield put(purchaseAddOnError('Operation unsuccessful'));
  }
}

export function* watchAddOns() {
  yield all([
    takeLatest(getAvailableNumbers.type, fetchAvailablePhoneNumbers),
    takeLatest(reserveSelectedNumber.type, reserveNumber),
    takeLatest(configureVoiceAddon.type, configureVoice),
    takeLatest(setAddOnsProductInstanceIds.type, fetchAddOnsPricesAndDiscounts),
    takeLatest(purchaseAddOn.type, purchaseAddOnSaga),
    takeLatest(getPurchasableAddOnsAction.type, fetchPurchasableAddOns),
  ]);
}
