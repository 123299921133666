import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Surface } from '@vst/beam';
import { useEffect } from 'react';

import {
  configureVoiceAddon,
  selectAddOns,
  selectCurrentShopAddon,
} from '@mfe/to-be-migrated/redux/addOns';
import useNavigate from '@mfe/services/navigation';
import { useScreenResolution } from '@mfe/shared/util';
import { HeaderWithBackNavigation } from '@mfe/shared/components';
import { VoiceAddonPhoneNumberType } from '@mfe/shared/schema-types';
import { ShopAddonsLink } from '@mfe/legacy/mv/utils/Navigation';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

import { ConfigureVoiceCard } from './card';
import { AddonCart } from '../cart';
import Steps from '../steps';
import styles from './styles.module.scss';

enum CountryCode {
  US = 'US',
}

export const ConfigureVoice = ({
  handleHeaderBackNavigation,
  handleFooterBackNavigation,
  handlePurchase,
}: {
  handleHeaderBackNavigation: () => void;
  handleFooterBackNavigation: () => void;
  handlePurchase: () => void;
}) => {
  const dispatch = useDispatch();
  const { replace } = useNavigate();
  const { t } = useTranslation('ShopAddons');

  const {
    userInfo: {
      firstName,
      lastName,
      address: { service: serviceAddress },
    },
  } = useSelector(selectUserInfo);
  const currentShopAddon = useSelector(selectCurrentShopAddon);
  const { voiceConfig } = useSelector(selectAddOns);

  const { isMedium, isLarge, isExtraLarge } = useScreenResolution();
  const isDesktop = isLarge || isExtraLarge;

  useEffect(() => {
    if (!currentShopAddon) {
      replace(`/${ShopAddonsLink.ShopAddons}`);
    }
  }, [replace, currentShopAddon]);

  if (!currentShopAddon) {
    return null;
  }

  const handleSubmit = () => {
    if (voiceConfig.directoryListingType) {
      const { aptUnit, ...requiredFields } = voiceConfig.emergencyAddress;
      const hasFilledOutCustomAddress = Object.values(requiredFields).every(
        (value) => value
      );

      dispatch(
        configureVoiceAddon({
          shoppingCartId: currentShopAddon.offer_id,
          productTypeId: currentShopAddon.id,
          voice: JSON.stringify({
            id: currentShopAddon.id,
            telephoneNumber: voiceConfig.selectedPhoneNumber,
            firstName,
            lastName,
            callerId: voiceConfig.callerId,
            blockCallerId: `${voiceConfig.blockCallerId || false}`,
            directoryListType: voiceConfig.directoryListingType,
            numberType: VoiceAddonPhoneNumberType.New,
            emergencyAddress: !hasFilledOutCustomAddress
              ? serviceAddress
              : {
                  addressLines: [
                    voiceConfig.emergencyAddress?.streetAddress,
                    voiceConfig.emergencyAddress.aptUnit,
                  ],
                  municipality: voiceConfig.emergencyAddress.cityAddress,
                  region: voiceConfig.emergencyAddress.stateProvince,
                  countryCode: CountryCode.US,
                  postalCode: voiceConfig.emergencyAddress.zipCode,
                },
            publishDirectoryListAddress: `${
              voiceConfig.publishDirectoryListAddress || false
            }`,
          }),
        })
      );

      handlePurchase();
    }
    return;
  };

  return (
    <div className={styles['wrapper']}>
      <HeaderWithBackNavigation
        handleBackNavigation={handleHeaderBackNavigation}
        title={currentShopAddon.name}
        goBackLabel={t('allAddOns')}
        additionalClassName={styles['voiceHeader']}
      />
      <Surface
        variant={isDesktop || isMedium ? 'secondary' : 'primary'}
        mb={isDesktop || isMedium ? '64px' : '8px'}
      >
        <Steps step={2} maxSteps={2} />
      </Surface>
      <div className={styles['container']}>
        <div className={styles.leftColumn}>
          <ConfigureVoiceCard
            handleBack={handleFooterBackNavigation}
            handleSubmit={handleSubmit}
          />
        </div>
        {isDesktop && (
          <AddonCart
            handleClick={handleSubmit}
            addon={currentShopAddon}
            submitButtonLabel={t('subscribeNow')}
          />
        )}
      </div>
    </div>
  );
};
