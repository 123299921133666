import {
  Checkbox,
  RadioGroup,
  SelectionCard,
  Txt,
  HelperText,
} from '@vst/beam';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectAddOns,
  setStepValid,
  setVoiceFormValues,
} from '@mfe/to-be-migrated/redux/addOns';
import { DirectoryListType } from '@mfe/shared/schema-types';
import { useScreenResolution } from '@mfe/shared/util';

import { Step } from './step';
import { StepInstanceProps } from './types';
import { TransitionWrapper } from './transition-wrapper';
import styles from './styles.module.scss';

export const Step4: FC<StepInstanceProps> = ({ isOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ShopAddons');

  const {
    voiceConfig: { isStepValid, directoryListingType },
  } = useSelector(selectAddOns);

  const handleOptionChange = (option: DirectoryListType) => {
    dispatch(setVoiceFormValues({ directoryListingType: option }));
    dispatch(setStepValid(true));
  };

  const showShareOption = [
    DirectoryListType.ListPublish,
    DirectoryListType.ListNotPublish,
  ].includes(directoryListingType as DirectoryListType);

  const { isLarge, isExtraLarge } = useScreenResolution();
  const isDesktop = isLarge || isExtraLarge;

  return (
    <Step
      step={4}
      title={t('voice.directoryListing.title')}
      handleEdit={() => null}
    >
      <TransitionWrapper isOpen={isOpen}>
        {isOpen && (
          <div className={styles.stepFourContent}>
            <Txt variant="bodySmallRegular" color="subtle">
              {t('voice.directoryListing.description')}
            </Txt>
            <Txt variant="bodySmallBold" mt="24px" mb="16px">
              {t('voice.directoryListing.prompt')}
            </Txt>
            <RadioGroup
              layout={isDesktop ? 'horizontal' : 'vertical'}
              name="number-listing-selection"
              className={styles.selectionCards}
              mb={{ xs: '32px', md: '40px' }}
            >
              {[
                DirectoryListType.ListPublish,
                DirectoryListType.ListNotPublish,
                DirectoryListType.NotListNotPublish,
              ].map((type) => (
                <SelectionCard
                  selectionType="radio"
                  body={t(`voice.directoryListing.values.${type}`)}
                  id={type}
                  value={type}
                  checked={directoryListingType === type}
                  onChange={() => handleOptionChange(type)}
                />
              ))}
            </RadioGroup>
            {isStepValid === false && (
              <HelperText
                state="error"
                helpMessage={t('voice.directoryListing.required')}
                className={styles.helperText}
              />
            )}
            {showShareOption && (
              <Checkbox
                // this selection needs to be submitted as publishDirectoryListAddress
                label={t('voice.directoryListing.share')}
                id="publish-directory-list-address"
                mb="40px"
              />
            )}
          </div>
        )}
      </TransitionWrapper>
    </Step>
  );
};
