import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setCurrentShopAddonId } from '@mfe/to-be-migrated/redux/addOns';
import { ShopAddonsLink } from '@mfe/legacy/mv/utils/Navigation';
import useNavigate from '@mfe/services/navigation';

import OneStepCheckout from '../one-step-checkout';
import { ConfigureVoice } from '../configure-voice';

enum AddOnCheckoutSteps {
  CHECKOUT = 'CHECKOUT',
  CONFIGURE_VOICE = 'CONFIGURE_VOICE',
}

export const Checkout = ({ handleSubmit }: { handleSubmit: () => void }) => {
  const dispatch = useDispatch();
  const { goTo } = useNavigate();

  const [step, setStep] = useState<AddOnCheckoutSteps>(
    AddOnCheckoutSteps.CHECKOUT
  );

  // So you can't navigate directly to Checkout if you've previously visited the page
  useEffect(() => {
    return () => {
      dispatch(setCurrentShopAddonId(null));
    };
  }, [dispatch]);

  switch (step) {
    case AddOnCheckoutSteps.CONFIGURE_VOICE:
      return (
        <ConfigureVoice
          handleHeaderBackNavigation={() => goTo(ShopAddonsLink.ShopAddons)}
          handleFooterBackNavigation={() =>
            setStep(AddOnCheckoutSteps.CHECKOUT)
          }
          handlePurchase={handleSubmit}
        />
      );
    case AddOnCheckoutSteps.CHECKOUT:
    default:
      return (
        <OneStepCheckout
          handleSubmit={handleSubmit}
          goToConfigure={() => setStep(AddOnCheckoutSteps.CONFIGURE_VOICE)}
        />
      );
  }
};
